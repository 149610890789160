/** @jsxImportSource @emotion/react */

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import CoinsIcon from '@marpla/ui/icons/Coins'
import GridIcon from '@marpla/ui/icons/Grid'
import MonitorIcon from '@marpla/ui/icons/Monitor'
import NewsIcon from '@marpla/ui/icons/News'
import UsersIcon from '@marpla/ui/icons/Users'
import WalletIcon from '@marpla/ui/icons/Wallet'

const navLinks = [
	{
		to: '/report/project',
		children: 'SEO',
		icon: GridIcon,
	},
	{
		to: '/bidmanager',
		children: 'Реклама',
		icon: MonitorIcon,
	},
	{
		to: '/abtests',
		children: 'A/B тесты',
		icon: ErrorOutlineIcon,
	},
	{
		to: '/sales',
		children: (<div style={{ position: 'relative' }}>
		Продажи
		<div
			style={{
				position: 'absolute',
				top: '-10px',
				right: '-25px',
				width: '31px',
				height: '14px',
				backgroundColor: '#7ac70c',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '4px',
			}}
		>
			<span style={{ color: 'white', fontSize: '9px', fontWeight: 'bold' }}>BETA</span>
		</div>
	</div>),
		icon: CoinsIcon,
	},
	{
		to: '/finance',

		children: (
			<div style={{position: 'relative'}}>
				Тарифы
				<div
					style={{
						position: 'absolute',
						top: '-10px',
						right: '-25px',
						width: '24px',
						height: '16px',
						backgroundColor: '#1e88f6',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '4px',
					}}
				>
					<span style={{color: 'white', fontSize: '12px', fontWeight: 'bold'}}>
						!
					</span>
				</div>
			</div>
		),
		icon: WalletIcon,
		important: true,
	},
	{
		to: '/partner',
		children: 'Партнерка',
		icon: UsersIcon,
	},
	{
		to: 'https://t.me/marpla_ru',
		children: 'Новости',
		icon: NewsIcon,
		blank: true,
	},
]

export default navLinks;
